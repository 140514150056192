import { convertIdToText } from '@lemontree-ai/lemontree-admin-common-front/utils/selectDataUtils';

export default class StockDetailModel {
  constructor() {
    this.status = 'UNREGISTERED'; // 상태
    this.stock_logo_image = ''; // 종목 이미지
    this.stock_logo_image_list = [];
    this.stock_image_url = []; // 대표이미지
    this.stock_info = {
      name: '', // 종목명(Ko)
      eng_name: '', // 종목명(En)
      ticker: '', // 종목코드
      stock_category_name: '', // 산업
      stock_category_id: '', // 산업
      market_cap: '', // 시가총액
      introduction: '', // 한줄소개
      sec_url: '', // sec 링크
    };
    this.sales_item = {
      percent: null,
      title: '',
      details: [''],
    };
    this.sales = [this.sales_item]; // 매출구성
    this.rivals_item = {
      name: '',
      ticker: '',
      hasLink: '',
    };
    this.rivals = [
      // 경쟁사 TOP3
      this.rivals_item,
      this.rivals_item,
      this.rivals_item,
    ];
    this.important_item = {
      title: '',
      details: '',
    };
    this.important = [this.important_item]; // 주목해야 할 포인트
    this.special_jobs_item = {
      name: '',
      engName: '',
    };
    this.special_jobs = [this.special_jobs_item]; // 스페셜 직업
    this.main_events_item = {
      type: '',
      year: '',
      title: '',
      details: '',
    };
    this.main_events = [this.main_events_item]; // 주요한 사건들
    // this.keyword_tag = [this.keyword_tag_item]; // 단어카드
    // this.keyword_tag_item = {
    //   name_ko: '',
    //   name_en: '',
    //   desd: '',
    // };

    // ETF
    this.etf_info = {
      // ETF 정보
      asset_management_insitution: '',
      inception_date: '',
      one_year_return: null,
      three_year_return: null,
    };
    this.etf_introduce_item = {
      title: '',
      desc: '',
    };
    this.etf_introduce = [this.etf_introduce_item]; // ETF 소개
    this.major_investment_firms_item = {
      connect_card: '',
      code: '',
      name: '',
    };
    this.major_investment_firms = [this.major_investment_firms_item]; // 대표적인 투자 기업
  }
  setCategoryName(categoryId) {
    this.stock_info.stock_category_name = convertIdToText(
      categoryId,
      'stock_industry',
    );
  }
  setData(obj) {
    const {
      status,
      stock_logo_image,
      stock_image_url,
      stock_info,
      sales,
      rivals,
      important,
      special_jobs,
      main_events,
      sec_link,
      // keyword_tag,

      etf_info,
      etf_introduce,
      major_investment_firms,
    } = obj;
    this.status = status;
    this.stock_logo_image = stock_logo_image;
    this.stock_image_url = stock_image_url;
    if (stock_logo_image) {
      this.stock_logo_image_list = [
        {
          src: stock_logo_image,
        },
      ];
    }
    this.stock_image_url = [];
    this.stock_info = stock_info || {
      name: '', // 종목명(Ko)
      eng_name: '', // 종목명(En)
      ticker: '', // 종목코드
      stock_category_name: '', // 산업
      stock_category_id: '', // 산업
      market_cap: '', // 시가총액
      introduction: '', // 한줄소개
      sec_url: '', // sec 링크
    };
    this.sales = sales || [this.sales_item];
    this.rivals = rivals || [
      this.rivals_item,
      this.rivals_item,
      this.rivals_item,
    ];
    this.important = important || [this.important_item];
    this.special_jobs = special_jobs || [this.special_jobs_item];
    this.main_events = main_events || [this.main_events_item];
    this.sec_link = sec_link;
    // this.keyword_tag = keyword_tag;
    this.etf_info = etf_info;
    this.etf_introduce = etf_introduce;
    this.major_investment_firms = major_investment_firms;
  }
  getData() {
    if (this.stock_info.stock_category_id === 'ETF') {
      return this.getEFTData();
    } else {
      return this.getBasicData();
    }
  }
  getBasicData() {
    let obj = {
      status: 'REGISTERED',
      stock_logo_image:
        this.stock_logo_image_list.length > 0
          ? this.stock_logo_image_list[0].src
          : '',
      stock_image_url: this.stock_image_url.map(item => item.src),
      stock_info: this.stock_info,
      sales: this.sales,
      rivals: this.rivals,
      important: this.important,
      special_jobs: this.special_jobs,
      main_events: this.main_events,
    };
    return obj;
  }
  getEFTData() {
    let obj = {
      etf_info: this.etf_info,
      etf_introduce: this.etf_introduce,
      major_investment_firms: this.major_investment_firms,
    };
    return obj;
  }
}
