<template>
  <TableView>
    <template v-slot:tbl_colgroup>
      <col style="width:118px" />
      <col />
      <col style="width:118px" />
      <col />
    </template>
    <template v-slot:tbl_top>
      <div class="area_left">
        <strong class="tit_tbl">기본정보</strong>
      </div>
    </template>
    <template v-slot:tbl_body>
      <tr>
        <th>
          상태<IconSvg iconName="essential" :size="6" iconColor="#F04848" />
        </th>
        <td colspan="3">
          <SwitchCheck
            :value.sync="statusSync"
            :isSwitchLeft="true"
            text="활성화"
            offText="비활성화"
          />
        </td>
      </tr>
      <tr>
        <th>
          종목이미지<IconSvg
            iconName="essential"
            :size="6"
            iconColor="#F04848"
          />
        </th>
        <td colspan="3">
          <FileUpload
            :dataList.sync="model.stock_logo_image_list"
            :canMultiple="false"
            :acceptList="['jpg', 'jpeg', 'png']"
            sizeType="FIT"
            :saveTrigger="saveTrigger1"
            :isSaved.sync="isSaved1Sync"
          />
        </td>
      </tr>
      <tr>
        <th>대표이미지</th>
        <td colspan="3">
          <FileUpload
            :dataList.sync="model.stock_image_url"
            :canMultiple="true"
            :maxSize="3"
            :acceptList="['jpg', 'jpeg', 'png']"
            sizeType="FIT"
            :saveTrigger="saveTrigger2"
            :isSaved.sync="isSaved2Sync"
          />
        </td>
      </tr>
      <tr>
        <th>
          종목명(Ko)<IconSvg
            iconName="essential"
            :size="6"
            iconColor="#F04848"
          />
        </th>
        <td>
          <Input :value.sync="model.stock_info.name" />
        </td>
        <th>
          종목명(En)<IconSvg
            iconName="essential"
            :size="6"
            iconColor="#F04848"
          />
        </th>
        <td>
          <Input :value.sync="model.stock_info.eng_name" />
        </td>
      </tr>
      <tr>
        <th>
          종목코드<IconSvg iconName="essential" :size="6" iconColor="#F04848" />
        </th>
        <td>
          <Input :value.sync="model.stock_info.ticker" />
        </td>
        <th>
          산업<IconSvg iconName="essential" :size="6" iconColor="#F04848" />
        </th>
        <td>
          <Selectbox
            :dataList="'stock_industry' | getSelectDataList"
            :value.sync="model.stock_info.stock_category_id"
            @update:value="value => model.setCategoryName(value)"
          />
        </td>
      </tr>
      <tr>
        <th>
          시가총액<IconSvg iconName="essential" :size="6" iconColor="#F04848" />
        </th>
        <td colspan="3">
          <Input :value.sync="model.stock_info.market_cap" />
        </td>
      </tr>
      <tr>
        <th>
          한줄소개<IconSvg iconName="essential" :size="6" iconColor="#F04848" />
        </th>
        <td colspan="3">
          <Textarea :value.sync="model.stock_info.introduction" :showLine="3" />
        </td>
      </tr>
      <tr>
        <th>sec 링크 URL</th>
        <td colspan="3">
          <Input :value.sync="model.stock_info.sec_url" />
        </td>
      </tr>
    </template>
  </TableView>
</template>
<script>
import TableView from '@lemontree-ai/lemontree-admin-common-front/components/common/table/TableView';
import IconSvg from '@lemontree-ai/lemontree-admin-common-front/components/common/icon/IconSvg';
import SwitchCheck from '@lemontree-ai/lemontree-admin-common-front/components/common/switch/SwitchCheck';
import Input from '@lemontree-ai/lemontree-admin-common-front/components/common/input/Input';
import Selectbox from '@lemontree-ai/lemontree-admin-common-front/components/common/selectbox/Selectbox';
import Textarea from '@lemontree-ai/lemontree-admin-common-front/components/common/textarea/Textarea';
import FileUpload from '@lemontree-ai/lemontree-admin-common-front/components/common/file/FileUpload';

export default {
  name: 'BasicInfo',
  components: {
    TableView,
    IconSvg,
    SwitchCheck,
    Input,
    Selectbox,
    Textarea,
    FileUpload,
  },
  props: {
    model: Object,
    saveTrigger1: Boolean,
    isSaved1: Boolean,
    saveTrigger2: Boolean,
    isSaved2: Boolean,
  },
  computed: {
    statusSync: {
      get() {
        return this.model.status === 'REGISTERED';
      },
      set(val) {
        let result = this.model.status;
        switch (val) {
          case true: {
            result = 'REGISTERED';
            break;
          }
          case false: {
            result = 'UNREGISTERED';
            break;
          }
        }
        this.model.status = result;
      },
    },
    isSaved1Sync: {
      get() {
        return this.isSaved1;
      },
      set(val) {
        this.$emit('update:isSaved1', val);
      },
    },
    isSaved2Sync: {
      get() {
        return this.isSaved2;
      },
      set(val) {
        this.$emit('update:isSaved2', val);
      },
    },
  },
};
</script>
