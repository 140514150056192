<template>
  <div>
    <!-- 매출구성 -->
    <TableList>
      <template v-slot:tbl_top>
        <div class="area_left">
          <strong class="tit_tbl">매출구성</strong>
        </div>
        <div class="area_right">
          <Button
            btnSize="small"
            btnStyle="secondary_border"
            text="추가하기"
            @onClickBtn="$emit('addData', 'sales')"
          />
        </div>
      </template>
      <template v-slot:tbl_colgroup>
        <col style="width:44px" />
        <col style="width:76px" />
        <col style="width:20%" />
        <col />
        <col style="width:74px" />
      </template>
      <template v-slot:tbl_head>
        <th>No.</th>
        <th>퍼센트</th>
        <th>타이틀</th>
        <th>내용</th>
        <th></th>
      </template>
      <template v-slot:tbl_body>
        <tbody>
          <tr
            v-for="(item, index) in model.sales"
            :key="`tr_${index}`"
          >
            <td>
              <span class="txt_tbl">{{ index + 1 }}</span>
            </td>
            <td clsss="area_overflow">
              <span class="area_right txt_tbl">%</span>
              <div class="area_overflow">
                <Input
                  :value.sync="item.percent"
                  :isRemoveBtn="false"
                  valueType="number" />
              </div>
            </td>
            <td>
              <Input :value.sync="item.title" />
            </td>
            <td>
              <div
                v-for="(info_item, info_index) in item.details"
                :key="`item_info_${info_index}`"
                class="group_form"
              >
                <Textarea
                  :value.sync="info_item"
                  :showLine="2"
                  style="flex:1 0"
                />
                <div style="width:84px;margin:4px;text-align:left">
                  <Button
                    v-if="item.details.length > 1"
                    btnSize="small"
                    btnStyle="fourthly_border"
                    text=""
                    @onClickBtn="
                      $emit(
                        'removeData',
                        'sales_item_details',
                        index,
                        info_index,
                      )
                    "
                  >
                    <IconSvg
                      iconName="subtract"
                      :size="12"
                      iconColor="#F04848"
                      style="margin:0"
                    />
                  </Button>
                  <Button
                    v-if="info_index === 0"
                    btnSize="small"
                    btnStyle="secondary_border"
                    text=""
                    @onClickBtn="
                      $emit(
                        'addData',
                        'sales_item_details',
                      index,
                      info_index,
                      )
                    "
                  >
                    <IconSvg
                      iconName="add"
                      :size="12"
                      iconColor="#333333"
                      iconDarkColor="#ffffff"
                      style="margin:0"
                    />
                  </Button>
                </div>
              </div>
            </td>
            <td>
              <Button
                btnSize="small"
                btnStyle="fourthly_border"
                text="삭제"
                @onClickBtn="$emit('removeData', 'sales', index)"
              />
            </td>
          </tr>
        </tbody>
      </template>
    </TableList>
    <!-- 경쟁사 TOP3 -->
    <TableList>
      <template v-slot:tbl_top>
        <div class="area_left">
          <strong class="tit_tbl">경쟁사 TOP3</strong>
        </div>
      </template>
      <template v-slot:tbl_colgroup>
        <col style="width:60px" />
        <col />
        <col />
      </template>
      <template v-slot:tbl_head>
        <th>Rank</th>
        <th>종목코드</th>
        <th>종목명</th>
      </template>
      <template v-slot:tbl_body>
        <tbody>
          <tr
            v-for="(item, index) in model.rivals"
            :key="`tr_${index}`"
          >
            <td>
              <span class="txt_tbl">{{ index + 1 }}</span>
            </td>
            <td>
              <Input :value.sync="item.ticker" />
            </td>
            <td>
              <Input :value.sync="item.name" />
            </td>
          </tr>
        </tbody>
      </template>
    </TableList>
    <!-- 주목해야 할 포인트 -->
    <TableList>
      <template v-slot:tbl_top>
        <div class="area_left">
          <strong class="tit_tbl">주목해야 할 포인트</strong>
        </div>
        <div class="area_right">
          <Button
            btnSize="small"
            btnStyle="secondary_border"
            text="추가하기"
            @onClickBtn="$emit('addData', 'important')"
          />
        </div>
      </template>
      <template v-slot:tbl_colgroup>
        <col style="width:60px" />
        <col />
        <col style="width:60px" />
      </template>
      <template v-slot:tbl_head>
        <th>No.</th>
        <th>타이틀 / 내용</th>
        <th></th>
      </template>
      <template v-slot:tbl_body>
        <tbody>
          <tr v-for="(item, index) in model.important" :key="`tr_${index}`">
            <td>
              <span class="txt_tbl">{{ index + 1 }}</span>
            </td>
            <td>
              <Input placeholder="타이틀" :value.sync="item.title" />
              <Textarea
                placeholder="내용"
                :value.sync="item.details"
                :showLine="3"
              />
            </td>
            <td>
              <Button
                btnSize="small"
                btnStyle="fourthly_border"
                text=""
                @onClickBtn="$emit('removeData', 'important', index)"
              >
                <IconSvg
                  iconName="subtract"
                  :size="12"
                  iconColor="#F04848"
                  style="margin:0"
                />
              </Button>
            </td>
          </tr>
        </tbody>
      </template>
    </TableList>
    <!-- 스페셜 직업 -->
    <TableList>
      <template v-slot:tbl_top>
        <div class="area_left">
          <strong class="tit_tbl">스페셜 직업</strong>
        </div>
        <div class="area_right">
          <Button
            btnSize="small"
            btnStyle="secondary_border"
            text="추가하기"
            @onClickBtn="$emit('addData', 'special_jobs')"
          />
        </div>
      </template>
      <template v-slot:tbl_colgroup>
        <col style="width:60px" />
        <col style="width:30%" />
        <col />
        <col style="width:60px" />
      </template>
      <template v-slot:tbl_head>
        <th>No.</th>
        <th>타이틀</th>
        <th>내용</th>
        <th></th>
      </template>
      <template v-slot:tbl_body>
        <tbody>
          <tr v-for="(item, index) in model.special_jobs" :key="`tr_${index}`">
            <td>
              <span class="txt_tbl">{{ index + 1 }}</span>
            </td>
            <td>
              <Input :value.sync="item.name" />
            </td>
            <td>
              <Input :value.sync="item.engName" />
            </td>
            <td>
              <Button
                btnSize="small"
                btnStyle="fourthly_border"
                text=""
                @onClickBtn="$emit('removeData', 'special_jobs', index)"
              >
                <IconSvg
                  iconName="subtract"
                  :size="12"
                  iconColor="#F04848"
                  style="margin:0"
                />
              </Button>
            </td>
          </tr>
        </tbody>
      </template>
    </TableList>
    <!-- 주요한 사건들 -->
    <TableList>
      <template v-slot:tbl_top>
        <div class="area_left">
          <strong class="tit_tbl">주요한 사건들</strong>
        </div>
        <div class="area_right">
          <Button
            btnSize="small"
            btnStyle="secondary_border"
            text="추가하기"
            @onClickBtn="$emit('addData', 'main_events')"
          />
        </div>
      </template>
      <template v-slot:tbl_colgroup>
        <col style="width:60px" />
        <col style="width:120px" />
        <col style="width:80px" />
        <col style="width:26%" />
        <col />
        <col style="width:60px" />
      </template>
      <template v-slot:tbl_head>
        <th>No.</th>
        <th>GOOD/BAD</th>
        <th>년도</th>
        <th>타이틀</th>
        <th>내용</th>
        <th></th>
      </template>
      <template v-slot:tbl_body>
        <tbody>
          <tr v-for="(item, index) in model.main_events" :key="`tr_${index}`">
            <td>
              <span class="txt_tbl">{{ index + 1 }}</span>
            </td>
            <td>
              <Selectbox
                :dataList="'stock_main_event_item_type' | getSelectDataList"
                :value.sync="item.type"
              />
            </td>
            <td>
              <Input placeholder="----" :value.sync="item.year" />
            </td>
            <td>
              <Input :value.sync="item.title" />
            </td>
            <td>
              <Textarea :value.sync="item.details" :showLine="4" />
            </td>
            <td>
              <Button
                btnSize="small"
                btnStyle="fourthly_border"
                text=""
                @onClickBtn="$emit('removeData', 'main_events', index)"
              >
                <IconSvg
                  iconName="subtract"
                  :size="12"
                  iconColor="#F04848"
                  style="margin:0"
                />
              </Button>
            </td>
          </tr>
        </tbody>
      </template>
    </TableList>
    <!-- 단어카드 -->
    <!-- <TableList>
      <template v-slot:tbl_top>
        <div class="area_left">
          <strong class="tit_tbl">단어카드</strong>
        </div>
        <div class="area_right">
          <Button
            btnSize="small"
            btnStyle="secondary_border"
            text="추가하기"
            @onClickBtn="$emit('addData', 'keyword_tag')"
          />
        </div>
      </template>
      <template v-slot:tbl_colgroup>
        <col style="width:60px" />
        <col />
        <col style="width:60px" />
      </template>
      <template v-slot:tbl_head>
        <th>No.</th>
        <th>단어 / 설명</th>
        <th></th>
      </template>
      <template v-slot:tbl_body>
        <tbody>
          <tr v-for="(item, index) in model.keyword_tag" :key="`tr_${index}`">
            <td>
              <span class="txt_tbl">{{ index + 1 }}</span>
            </td>
            <td>
              <div class="group_form">
                <Input placeholder="단어(Ko)" :value.sync="item.name_ko" />
                <Input placeholder="단어(En)" :value.sync="item.name_en" />
              </div>
              <Textarea
                placeholder="설명"
                :value.sync="item.desd"
                :showLine="3"
              />
            </td>
            <td>
              <Button
                btnSize="small"
                btnStyle="fourthly_border"
                text=""
                @onClickBtn="$emit('removeData', 'keyword_tag', index)"
              >
                <IconSvg
                  iconName="subtract"
                  :size="12"
                  iconColor="#F04848"
                  style="margin:0"
                />
              </Button>
            </td>
          </tr>
        </tbody>
      </template>
    </TableList> -->
  </div>
</template>
<script>
import TableList from '@lemontree-ai/lemontree-admin-common-front/components/common/table/TableList';
import IconSvg from '@lemontree-ai/lemontree-admin-common-front/components/common/icon/IconSvg';
import Input from '@lemontree-ai/lemontree-admin-common-front/components/common/input/Input';
import Textarea from '@lemontree-ai/lemontree-admin-common-front/components/common/textarea/Textarea';
import Button from '@lemontree-ai/lemontree-admin-common-front/components/common/button/Button';
import Selectbox from '@lemontree-ai/lemontree-admin-common-front/components/common/selectbox/Selectbox';

export default {
  name: 'StockDetailForm',
  components: {
    TableList,
    IconSvg,
    Input,
    Textarea,
    Button,
    Selectbox,
  },
  props: {
    model: Object,
  },
};
</script>
