import { GET_REQUEST, PUT_REQUEST } from '@/services/http';
import apiPath from '@/services/apiPath';
import StockDetailModel from '@/views/stock/stocks/model/StockDetailModel';
import store from '@lemontree-ai/lemontree-admin-common-front/store/index';

export default class MemberDetailViewModel {
  constructor() {
    this.model = new StockDetailModel();
    this.isModify = false;
  }

  init(id) {
    if(id){
      this.isModify = true;
      this.getStockDetail(id);
    }
  }
  addData(type, index) {
    var parentDataName = '';
    var itemDataName = '';
    switch (type) {
      // 매출구성
      case 'sales': {
        parentDataName = 'sales';
        itemDataName = this.model.sales_item;
        break;
      }
      // 매출구성 > 내용
      case 'sales_item_details': {
        // parentDataName = 'sales_item[0].details';
        // itemDataName = '';
        this.model.sales[index].details.push('');
        return;
      }
      // 주목해야 할 포인트
      case 'important': {
        parentDataName = 'important';
        itemDataName = this.model.important_item;
        break;
      }
      // 스페셜 직업
      case 'special_jobs': {
        parentDataName = 'special_jobs';
        itemDataName = this.model.special_jobs_item;
        break;
      }
      // 주요한 사건들
      case 'main_events': {
        parentDataName = 'main_events';
        itemDataName = this.model.main_events_item;
        break;
      }
      // 단어카드
      // case 'keyword_tag': {
      //   parentDataName = 'keyword_tag';
      //   itemDataName = this.model.keyword_tag_item;
      //   break;
      // }
      // ETF 소개
      case 'etf_introduce': {
        parentDataName = 'etf_introduce';
        itemDataName = this.model.etf_introduce_item;
        break;
      }
      // 대표적인 투자 기업
      case 'major_investment_firms': {
        parentDataName = 'major_investment_firms';
        itemDataName = this.model.major_investment_firms_item;
        break;
      }
    }
    this.model[parentDataName].push(itemDataName);
  }
  removeData(type, index, subIndex) {
    var parentDataName = '';
    var itemDataName = '';
    switch (type) {
      // 매출구성
      case 'sales': {
        parentDataName = 'sales';
        itemDataName = this.model.sales_item;
        break;
      }
      // 매출구성 > 내용
      case 'sales_item_details': {
        if (this.model.sales[index].details.length === 1) {
          this.model.sales[index].details.splice(subIndex, 1, '');
        } else {
          this.model.sales[index].details.splice(subIndex, 1);
        }
        return;
      }
      // 주목해야 할 포인트
      case 'important': {
        parentDataName = 'important';
        itemDataName = this.model.important_item;
        break;
      }
      // 스페셜 직업
      case 'special_jobs': {
        parentDataName = 'special_jobs';
        itemDataName = this.model.special_jobs_item;
        break;
      }
      // 주요한 사건들
      case 'main_events': {
        parentDataName = 'main_events';
        itemDataName = this.model.main_events_item;
        break;
      }
      // 단어카드
      // case 'keyword_tag': {
      //   parentDataName = 'keyword_tag';
      //   itemDataName = this.model.keyword_tag_item;
      //   break;
      // }

      // ETF 소개
      case 'etf_introduce': {
        parentDataName = 'etf_introduce';
        itemDataName = this.model.etf_introduce_item;
        break;
      }
      // 대표적인 투자 기업
      case 'major_investment_firms': {
        parentDataName = 'major_investment_firms';
        itemDataName = this.model.major_investment_firms_item;
        break;
      }
    }
    if (this.model[parentDataName].length === 1) {
      this.model[parentDataName].splice(index, 1, itemDataName);
    } else {
      this.model[parentDataName].splice(index, 1);
    }
  }

  // 상세
  getStockDetail(id){
    const path = apiPath.STOCK_STOCKS_DETAIL.format(id);
    GET_REQUEST(path).then(
    (success) => {
      const resultData = success.data.body;
      this.model.setData(resultData)
    }, (failed) => {
      store.dispatch('commonToast/fetchToastStart', failed.msg);
    })
  }

  // 수정, 등록
  putStockDetail(){
    const path = apiPath.STOCK_STOCKS_MODIFY.format(this.model.stock_info.ticker);
    const body = this.model.getData();
    PUT_REQUEST(path,body).then(
    (success) => {
      const resultData = success.data.body;
      store.dispatch('commonToast/fetchToastStart', `주식종목이 ${this.isModify ? '수정':'등록'}되었습니다.`);
      this.getStockDetail(this.model.stock_info.ticker)
    }, (failed) => {
      store.dispatch('commonToast/fetchToastStart', failed.msg);
    })
  }
}
