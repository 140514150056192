<!-- eslint-disable prettier/prettier -->
<template>
  <PageWithLayout>
    <!-- <div class="area_overflow">
      <div class="area_right">
        <div class="box_preview">
          <div class="head_perview">
            <button class="btn_close">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <g clip-path="url(#clip0_3310_18379)">
                  <path d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z" fill="#232323"/>
                </g>
                <defs>
                  <clipPath id="clip0_3310_18379">
                    <rect width="24" height="24" fill="white"/>
                  </clipPath>
                </defs>
              </svg>
            </button>
          </div>
          <div class="card_stock">
            <div class="body_card">
              <span class="txt_industry">{{ this.viewModel.model.industry }}</span>
              <div class="area_img">
                <div class="img_stock_logo">종목로고</div>
                <div class="img_country">국기</div>
              </div>
              <strong class="txt_code">{{ this.viewModel.model.code }}</strong>
              <p class="desc_my_stock">내 주식<span class="txt_shares">0.00001주</span></p>
            </div>
            <div class="foot_card">
              <div class="area_left img_level">새싹이미지</div>
              <div class="area_overflow">
                <p class="desc_hold_amount">6,161원 보유</p>
                <p class="desc_value_shift">+1,204원<span class="txt_percent">1.2%</span></p>
              </div>
            </div>
          </div>
          <PreviewDefault
            v-if="viewModel.model.industry != 'ETF'"
            :model.sync="viewModel.model" />
          <PreviewEtf
            v-else
            :model.sync="viewModel.model" />
        </div>
      </div> -->
      <div class="cont_form area_overflow">
        <!-- 기본정보 -->
        <BasicInfo
          :model.sync="viewModel.model"
          :saveTrigger1.sync="saveTrigger1"
          :isSaved1.sync="isSaved1"
          :saveTrigger2.sync="saveTrigger2"
          :isSaved2.sync="isSaved2"/>
        <!-- 기본 폼 -->
        <StockDetailForm
          v-if="viewModel.model.stock_info?.stock_category_id != 'ETF'"
          :model.sync="viewModel.model"
          @addData="(type,index) => viewModel.addData(type,index)"
          @removeData="(type,index,subIndex) => viewModel.removeData(type,index,subIndex)"/>
        <!-- ETF 폼 -->
        <StockDetailEtfForm
          v-else
          :model.sync="viewModel.model"
          @addData="(type) => viewModel.addData(type)"
          @removeData="(type,index) => viewModel.removeData(type,index)"/>
      </div>
    <!-- </div> -->
    <Sticky>
      <div class="area_left">
        <Button
          btnSize="medium"
          btnStyle="secondary_border"
          text="목록"
          routeName="STOCK_STOCKS_LIST"
          :routeQuery="{ pageSize : $route.query.pageSize, page : $route.query.page }"/>
      </div>
      <div class="area_right">
        <Button
          btnSize="medium"
          btnStyle="primary"
          text="저장"
          :disabled="!viewModel.model.stock_info.ticker"
          @onClickBtn="onClickComplete()"/>
      </div>
    </Sticky>
  </PageWithLayout>
</template>

<script>
import PageWithLayout from '@lemontree-ai/lemontree-admin-common-front/components/layout/PageWithLayout';
import BasicInfo from '@/views/stock/stocks/view/components/BasicInfo';
import StockDetailForm from '@/views/stock/stocks/view/components/StockDetailForm';
import StockDetailEtfForm from '@/views/stock/stocks/view/components/StockDetailEtfForm';
import Sticky from '@lemontree-ai/lemontree-admin-common-front/components/common/sticky/Sticky';
import Button from '@lemontree-ai/lemontree-admin-common-front/components/common/button/Button';
// viewModel
import StockDetailViewModel from '@/views/stock/stocks/viewModel/StockDetailViewModel';
// mixin
import FileSaveMixin from '@lemontree-ai/lemontree-admin-common-front/mixins/FileSaveMixin';

export default {
  name: 'StockRegister',
  components: {
    PageWithLayout,

    BasicInfo,
    StockDetailForm,
    StockDetailEtfForm,
    Sticky,
    Button,
  },
  mixins: [FileSaveMixin],
  watch: {
    isSaved1() {
      if (this.isSaved1) {
        console.log('isSaved1-watch')
        this.checkFileUpload();
      }
    },
    isSaved2() {
      if (this.isSaved2) {
        console.log('isSaved2-watch')
        this.checkFileUpload();
      }
    },
  },
  beforeMount(){
    const id = this.$route.params.id;
    this.viewModel.init(id);
  },
  data() {
    return {
      viewModel: new StockDetailViewModel(),
      saveTrigger1: false,
      isSaved1: false,
      saveTrigger2: false,
      isSaved2: false,
    };
  },
  methods: {
    onClickComplete() {
      console.log('onClickComplete')
      const isFileUpload1 = Boolean(
        this.viewModel.model.stock_logo_image_list.length > 0 &&
        this.viewModel.model.stock_logo_image_list[0].file,
      );
      const isFileUpload2 = Boolean(
        this.viewModel.model.stock_image_url.length > 0 &&
        this.viewModel.model.stock_image_url[0].file,
      );

      this.saveTrigger1 = isFileUpload1;
      this.saveTrigger2 = isFileUpload2;

      if (!isFileUpload1 && !isFileUpload2) {
        this.onCompleteFileSave();
      }
    },
    checkFileUpload() {
      console.log('checkFileUpload')
      var fileVaild = true;
      if (this.saveTrigger1 && !this.isSaved1) fileVaild = false;
      if (this.saveTrigger2 && !this.isSaved2) fileVaild = false;
      if (fileVaild) {
        console.log('checkFileUpload-done')
        this.onCompleteFileSave();
      }
    },
    onCompleteFileSave() {
      console.log('onCompleteFileSave')
      this.viewModel.putStockDetail();
    },
  },
};
</script>

<style>
.cont_lemontree.cont_stock_detail #mArticle{padding-bottom:0}
</style>

<style scoped>
/* .cont_form{height:calc(100vh - 220px);overflow-y:auto} */
.cont_form >>> .tbl_view + div,
.cont_form >>> .tbl_view + .tbl_list,
.cont_form >>> .tbl_list + .tbl_list,
.cont_form >>> .tbl_list + .tbl_view {
  margin-top: 26px;
}
.cont_form >>> .tbl_list .tbl_body td {
  vertical-align: top;
}
.cont_form >>> .tbl_list .tbl_body td .txt_tbl {
  line-height: 38px;
}
.cont_form >>> .tbl_list .tbl_body td .tf_comm + .tf_comm,
.cont_form >>> .tbl_list .tbl_body td .tf_comm + .textarea_comm,
.cont_form >>> .tbl_list .tbl_body td .group_form + .textarea_comm {
  margin-top: 6px;
}
.cont_form >>> .tbl_list .tbl_body td .group_form .tf_comm + .tf_comm {
  margin: 4px;
}
</style>
